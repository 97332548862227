import React, {useState} from 'react'
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import MapInfo from '../MapInfo/MapInfo';

const Maps = withScriptjs(withGoogleMap((props) => {
    const [latLng, setLatLng] = useState({
        lat: -34.5871865,
        lng: -58.3879444
    })

    const Quintana = () => {
        setLatLng({
            lat: -34.588580,
            lng: -58.38989
        })
    }
    const Central = () => {
        setLatLng({
            lat: -34.5871865,
            lng: -58.3879444
        })
    }
    return (
        <>
        <GoogleMap
            defaultZoom={19}
            defaultCenter={{ lat: latLng.lat, lng: latLng.lng }}
            center={{ lat: latLng.lat, lng: latLng.lng }}
        >
            {props.isMarkerShown && <Marker position={{ lat: latLng.lat, lng: latLng.lng }} />}
        </GoogleMap>
        <MapInfo 
        toQuintana={() => Quintana()} 
        toCentral={() => Central()}/>
        </>
    )
}))

export default Maps;
