import React from 'react'
import styles from './Operar.module.scss'
import registras from '../../images/registras.png'
import transferis from '../../images/transferis.png'
import compras from '../../images/compras.png'
import CardItem from './CardItem/CardItem'
const Operar = ({toggle}) => {
    return (
        <section className={styles.container}>
            <h2>
            Operamos en todo el país.
            <span>¿Cómo es el proceso?</span>
            </h2>
            <div className={styles.cards}>
                <CardItem 
                img={registras}
                text="Te registras como cliente
                en nuestra App."
                />
                <CardItem 
                img={transferis}
                text="Transferís pesos o dólares desde tu banco."
                />
                <CardItem 
                img={compras}
                text="Comprá o vendé al mejor precio del mercado."
                />
            </div>
            <button onClick={toggle} className={styles.link} href="#!">Quiero Operar</button>
        </section>
    )
}

export default Operar
