import React from 'react'
import styles from './ServiciosItem.module.scss';


const ServiciosItem = ({img, text, width}) => {
    return (
        <div className={styles.container}>
            <div className={styles.imgContainer}>
                <img src={img} alt={text}/>
            </div>
            <div className={styles.description} style={{maxWidth: `${width ? '108px' : null}`}}>
                {text}
            </div>
        </div>
    )
}

export default ServiciosItem
