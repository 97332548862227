import React from 'react'
import styles from './AppStore.module.scss' 
import Pantalla from '../../images/pantalla-phone-skin.png'
const AppStore = () => {
    return (
        <section className={styles.container}>
            <div className={styles.textContainer}>
                <h2>El mejor cambio está en tus manos.</h2>
                <p>Descargá nuestra aplicación totalmente gratis y comprá moneda extrangera de forma FÁCIL Y SEGURA sin moverte de tu casa.</p>
                <div className={styles.linksContainer}>
                    <a className={styles.playstore} href="https://play.google.com/store/apps/details?id=com.paisanoscreando.cambioposadas" target="_blank" rel="noopener noreferrer">
                    Play Store
                    </a>
                    <a className={styles.applestore} href="https://itunes.apple.com/ar/app/cambio-posadas/id1345520805?mt=8" target="_blank" rel="noopener noreferrer">
                    App Store
                    </a>
                </div>
            </div>
            <div className={styles.imgContainer}>
                <img src={Pantalla} alt="Pantalla"/>
            </div>
        </section>
    )
}

export default AppStore
