import React from 'react'
import styles from './CardItem.module.scss'
const CardItem = ({img, text}) => {
    return (
        <div className={styles.cardContainer}>
            <div className={styles.imgContainer}>
                <img src={img} alt="Registro"/>
            </div>
            <p>
                {text}                
            </p>
        </div>
    )
}

export default CardItem
