import React from 'react'
import styles from './DetailsMobileItem.module.scss'

const DetailsMobileItem = ({img, title, description}) => {
    return (
        <div className={styles.boxItem}>
            <div className={styles.imgContainer}>
                <img src={img} alt={description}/>
            </div>
            <div className={styles.textContainer}>
                {title}
                <span>{description}</span>
            </div>
        </div>
    )
}

export default DetailsMobileItem
