import React, {useState} from 'react'
import Header from '../components/Header/Header'
import Buy from '../components/Buy/Buy'
import AppStore from '../components/AppStore/AppStore'
import Operar from '../components/Operar/Operar'
import Details from '../components/Details/Details'
import DetailsMobile from '../components/DetailsMobile/DetailsMobile'
import NuestrosServicios from '../components/NuestrosServicios/NuestrosServicios'
import Maps from '../components/Maps/Maps'
import ContactForm from '../components/ContactForm/ContactForm'
import NuestraUbicacion from '../components/NuestraUbicacion/NuestraUbicacion'
import RegisterForm from '../components/RegisterForm/RegisterForm'

const Home = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    return (
        <>
            <Header toggle={toggle}/>
            <main style={{padding: '42px'}}>
                <Buy toggle={toggle} />
                <AppStore />
                <Operar toggle={toggle}/>
                <Details toggle={toggle}/>
            </main>
            <DetailsMobile />
            <NuestrosServicios />
            <Maps 
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCWVyTyh8TcB2EBFbNXsVOZQAs5cHbcktI&libraries=places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div className='gMap' style={{ height: `488px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            />
            <NuestraUbicacion />
            <ContactForm />
            <RegisterForm opened={isOpen} close={toggle} />
        </>
    )
}

export default Home
