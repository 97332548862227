import React from 'react'
import Nav from '../Nav/Nav'
import styles from './Header.module.scss'
import Arrow from '../../images/down-arrow.png'
import CurrencyBox from '../CurrencyBox/CurrencyBox'
const Header = ({toggle}) => {
    return (
        <>
            <Nav toggle={toggle}/>
            <header className={styles.header}>
                <div className={styles.textHeader}>
                <h2>Comprá y vendé <strong>dólares más barato</strong> que en tu banco</h2>
                <p>Encontrá el mejor tipo de cambio sin moverte de tu casa, negocio, o empresa con la mayor seguridad, sin perder tiempo y al mejor precio. 100% online, rapido y seguro. </p>
                </div>
                <CurrencyBox toggle={toggle}/>

                <img onClick={() => window.scrollTo(0, 1000)} className={styles.down} src={Arrow} alt="Page Down"/>
            </header>
        </>
    )
}

export default Header
