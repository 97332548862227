import React from 'react'
import Modal from '../Modal/Modal'
import {Formik, Field} from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import user from '../../images/user.svg';
import Input from '../UI/Input'
import axios from 'axios';
import Swal from 'sweetalert2';
import envelope from '../../images/envelope.svg'
import lock from '../../images/lock.svg'

const StyledText = styled.div`
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &::before {
        content:'';
        position: absolute;
        background-image: url(${user});
        background-size: cover;
        top: 50%;
        transform: translateY(-50%);
        left: -20px;
        width: 10px;
        height: 10px;
    }
`;

const TypeUserContainer = styled.div`
margin-top: 30px;
display: flex;
justify-content: space-around;
align-items: center;
width: 100%;
label {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background-color: #fafafa;
    border: 1px dashed #5a5a5a;
}
input {
    display: none;
    &:checked + label {
        background-color: #3AAC7F;
        cursor: pointer;
    }

    &:disabled + label {
        background-color: #eee;
        cursor: pointer;
    }
}
`;


const TextContainer = styled.div`
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
`;

const NameContainer = styled.div`
width: 100%;
margin-top: 20px;
display: flex;
justify-content: space-between;
align-items: center;

label {
    display: none;
}
input {
    border: none;
    background-color: #F8F9FB;
    color: rgba(0, 0, 0, 0.54);
    font-family: Nunito;
    font-size: 12px;
    font-weight: 300;
    line-height: 21px;
    padding-left: 40px;
    width: 206px;
    max-width: 100%;
    height: 45px;
    text-align: left;
    margin-top: 11px;
    border-bottom: 1px solid #54DAA1;
}
`;

const FormGroup = styled.div`
margin-top: 10px;
width: 100%;
position: relative;
&.envelope {

    &::before {
        content:'';
        position: absolute;
        left: 9px;
        top: 23px;
        background-image: url(${envelope});
        background-size: cover;
        width: 20px;
        height: 20px;
        z-index: 1;
    }
}

&.password::before {
    content:'';
        position: absolute;
        left: 9px;
        top: 23px;
        background-image: url(${lock});
        background-size: cover;
        width: 20px;
        height: 20px;
        z-index: 1;
}

label {
    display: none;
}

input {
    border: none;
    background-color: #F8F9FB;
    color: rgba(0, 0, 0, 0.54);
    font-family: Nunito;
    font-size: 12px;
    font-weight: 300;
    line-height: 21px;
    padding-left: 40px;
    width: 416px;
    max-width: 100%;
    height: 45px;
    text-align: left;
    margin-top: 11px;
    border-bottom: 1px solid #54DAA1;
}
`;

const ButtonContainer = styled.div`
margin: 30px auto;
button {
    background-image: linear-gradient(180deg, #7399FD 0%, #3F60FB 100%);
    border-radius: 32px;
    box-shadow: 0 6px 19px 0 rgba(0,0,0,.24);
    width: 142px;
    max-width: 100%;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
    margin: 0 auto;
    transition: .3s ease;

    &:disabled {
        color: #5a5a5a;
        background-image: linear-gradient(180deg, #eee 0%, #ccc 100%);
        cursor: not-allowed;
    }
}
`;

const RegisterForm = ({opened, close}) => {

    const registerSchema = Yup.object().shape({

        registerName: Yup.string()
        .required('El campo Nombre es requerido.'),

        registerLastName: Yup.string()
        .required('El campo Apellido es requerido.'),

        registerEmail: Yup.string()
        .email('El mail no es válido')
        .required('El campo Mail es requerido.'),

        password: Yup.string()
            .required('El campo password es requerido')
            .min(6, 'Debe contener al menos 6 caracteres.'),

        confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], `La contraseña no coincide.`)
        .required('Por favor confirma la contraseña.')

    });


    const SendForm = (values, {resetForm}) => {

        const {userType, registerName, registerLastName, registerEmail, password} = values;

        const formData = new FormData();
        formData.append('name', registerName);
        formData.append('lastName', registerLastName);
        formData.append('email', registerEmail);
        formData.append('password', password);
        formData.append('userType', userType);

        axios({
            method: 'POST',
            url: 'https://api.cambioposadas.com.ar/public/user',
            headers: {
                Authorization: 'Basic Q2FtYmlvUG9zYWRhczpGYWN1SXNHYXlzaGFu'
            },
            data: formData
        })
        .then(res => {
            Swal.fire({
                customClass:'modal-success',
                type:'success',
                title: '¡Bienvenido!',
                text: 'Tu cuenta ha sido creada con éxito, verifica tu correo electrónico por favor.',
                confirmButtonText: 'Aceptar'
            })
        })
        .catch(err => {
            Swal.fire({
                customClass:'modal-error',
                type:'error',
                title: '¡Lo sentimos!',
                text: `Ha ocurrido un error, por favor intentá nuevamente más tarde.`,
                confirmButtonText: 'Aceptar'
            })
        })

        resetForm({
            userType: 'habilitado',
            registerName: '',
            registerLastName: '',
            registerEmail: '',
            password: '',
            confirmPassword: ''
        })

        close()
    }

    const ShowAlert = () => {
        Swal.fire({
            customClass: 'modal-info',
            type:'info',
            title: 'Cambio Posadas',
            text:'Para darse de alta como Persona Jurídica llámenos al 0800-220-CAMBIO o escribanos a info@cambioposadas.com.ar, uno de nuestros ejecutivos de cuenta se contactará con ustedes a la brevedad para ofrecerle todas las opciones disponibles para cambiar divisas al mejor precio.',
            confirmButtonText: 'Aceptar'
        })
    }

    return (
        <Modal opened={opened} close={close}>
            <Formik
            initialValues={{
                userType: 'habilitado',
                registerName: '',
                registerLastName: '',
                registerEmail: '',
                password: '',
                confirmPassword: ''
            }}
            validationSchema={registerSchema}
            onSubmit={(values, {resetForm}) => SendForm(values, {resetForm}, close)}
            >
                {({handleSubmit, isValid}) => (
                    <>
                    <StyledText>
                        Tipo de Usuario
                    </StyledText>
                    <form style={{width: '100%'}} onSubmit={handleSubmit}>
                    <TypeUserContainer>
                        <Field defaultChecked="habilitado" type="radio" id="arg" name="userType" value="habilitado" />
                        <label htmlFor="arg">
                            <span>Argentino</span>
                            <span>Residente</span>
                            <span>Turista</span>
                        </label>
                        
                        <Field type="radio" id="negocio" name="userType" value="negocio" disabled/>
                        <label htmlFor="negocio" onClick={ShowAlert}>Negocio</label>
    
                        <Field type="radio" id="empresa" name="userType" value="empresa" disabled/>
                        <label htmlFor="empresa" onClick={ShowAlert}>Empresa</label>
                    </TypeUserContainer>
                    <TextContainer>
                    ¡Genial! Sigamos …<br/>
                    Ahora solo queda ncompletar algunos datos personales.
                    </TextContainer>
                    <NameContainer>
                        <label htmlFor="registerName">Nombre</label>
                        <Field id="registerName" placeholder="Nombre" name="registerName" component={Input}/>
    
                        <label htmlFor="registerLastName">Apellido</label>
                        <Field id="registerLastName" placeholder="Apellido" name="registerLastName" component={Input}/>
    
                    </NameContainer>
                    <FormGroup className="envelope">
                        <label htmlFor="registerEmail">Email</label>
                        <Field type="email" placeholder="Escribe tu mail ..." name="registerEmail" id="registerEmail" component={Input}/>
                    </FormGroup>
                    <FormGroup className="password">
                        <label htmlFor="password">Contraseña</label>
                        <Field type="password" placeholder="Escribe tu contraseña ..." name="password" id="password" component={Input}/>
                    </FormGroup>
                    <FormGroup className="password">
                        <label htmlFor="confirmPassword">Confirma tu contraseña</label>
                        <Field type="password" placeholder="Confirma tu contraseña ..." name="confirmPassword" id="confirmPassword" component={Input}/>
                    </FormGroup>
                    <ButtonContainer>
                        <button type="submit" disabled={!isValid}>Enviar</button>
                    </ButtonContainer>
                    </form>
                    </>
                )}
            </Formik>
        </Modal>
    )
}

export default RegisterForm
