import React from 'react'
import styles from './Nav.module.scss';
import Logo from '../../images/logo-cambio-header.png';
import {ReactComponent as PhoneIcon} from '../../images/call-answer.svg'
import {ReactComponent as User} from '../../images/avatar.svg'
import Login from '../../images/avatar-mobile.png';
const Nav = ({toggle}) => {
    return (
        <>
        <div className={styles.container}>
            <nav className={styles.nav}>

                <h1 className={styles.logoContainer}>
                    <img src={Logo} alt="Cambio Posadas"/>
                </h1>
                
                <ul className={styles.navItems}>
                    <li>
                        <a className={styles.phoneNumber} href="tel:0800220226246"><PhoneIcon/>0800-222-CAMBIO</a>
                    </li>
                    <li>
                        <button onClick={toggle} className={styles.login} href="!#"><User />Registrarse</button>
                    </li>
                    <li>
                        <button onClick={toggle} className={styles.loginMobile} href="!#"><img src={Login} alt="Login"/></button>
                    </li>
                </ul>
                
            </nav>
        </div>
        <a className={styles.phoneNumberMobile} href="tel:0800220226246"><PhoneIcon/>0800-222-CAMBIO</a>
        </>
    )
}

export default Nav
