import React from 'react';
import styled from 'styled-components';


const InputWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const StyledInput = styled.input`
  border: 0.7px solid rgba(151, 151, 151, 0.20);
  padding: 10px 15px;
  outline: none;
  width: 100%;
  &:focus, &:active{
    border-bottom: 3px solid #54DAA1;
  }
`;

const Error = styled.div`
color: red;
visibility: ${({show}) => (show ? 'visible' : 'hidden')};
opacity: ${({show}) => (show ? '1' : '0')};
transform: translateY(${({show}) => (show ? '20px' : '10px')}); 
transition: all 0.1s;
position: absolute;
left: -20px;
bottom: 0;
padding: 0 2rem;
font-size: 1.2rem;
`;

const Input = ({field, form : {touched, errors}, ...props}) => {
    return (
        <InputWrapper>
            <StyledInput {...field}{...props}/>
            <Error show={errors[field.name] && touched[field.name]}>{errors[field.name]}</Error>
        </InputWrapper>
        
    )
}

export default Input;