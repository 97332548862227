import React from 'react'
import styles from './NuestrosServicios.module.scss';

import usd from '../../images/usd.png'
import chl from '../../images/chl.png'
import can from '../../images/can.png'
import ecu from '../../images/ecu.png'
import eur from '../../images/eur.png'
import uk from '../../images/uk.png'
import swiss from '../../images/swiss.png'
import irn from '../../images/irn.png'
import uru from '../../images/uru.png'
import ch from '../../images/ch.png'
import mx from '../../images/mx.png'
import brl from '../../images/brl.png'
import ServiciosItem from './ServiciosItem/ServiciosItem';
import transaccion from '../../images/transaccion.png';
import envios from '../../images/envios.png';
import online from '../../images/online.png';

const NuestrosServicios = () => {
    return (
        <section className={styles.container}>
            <h2>Nuestros Servicios</h2>
            <div className={styles.content}>
                <div className={styles.flagsContainer}>
                    <span>
                    <img src={usd} alt="USD"/>
                    <img src={chl} alt="chl"/>
                    <img src={can} alt="can"/>
                    <img src={ecu} alt="ecu"/>
                    <img src={eur} alt="eur"/>
                    <img src={uk} alt="uk"/>
                    </span>
                
                    <span>
                    <img src={swiss} alt="swiss"/>
                    <img src={irn} alt="irn"/>
                    <img src={uru} alt="uru"/>
                    <img src={ch} alt="ch"/>
                    <img src={mx} alt="mx"/>
                    <img src={brl} alt="brl"/>
                    </span>
                   
                </div>
                <div className={styles.items}>
                    <ServiciosItem 
                    img={transaccion}
                    text="Transacciones en efectivo en nuestra sucursal (Recoleta)"
                    />
                    <ServiciosItem
                    width={true} 
                    img={envios}
                    text="Envíos a domicilio (CABA)"
                    />
                    <ServiciosItem 
                    width={true}
                    img={online}
                    text="Transacciones 100% Online"
                    />
                </div>
            </div>
        </section>
    )
}

export default NuestrosServicios
