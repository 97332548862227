import React from 'react'
import styles from './DetailItem.module.scss'
const DetailItem = ({img, title, width, height, description}) => {
    return (
        <div className={styles.box}>
            <img style={{width: width, height: height, marignTop: '50px'}} src={img} alt={description}/>
            <div className={styles.description}>
                {title} <span>{description}</span>
            </div>
        </div>
    )
}

export default DetailItem
