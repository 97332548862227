import React from 'react';
import styles from './Buy.module.scss';
import Currency from '../CurrencyBox/Currency';

const Buy = ({toggle}) => {
    return (
        <section className={styles.section}>
            <div className={styles.textHeader}>
            <h2>Comprá y vendé <strong>dólares más barato</strong> que en tu banco</h2>
            <p>Encontrá el mejor tipo de cambio sin moverte de tu casa, negocio, o empresa con la mayor seguridad, sin perder tiempo y al mejor precio. 100% online, rapido y seguro. </p>
            </div>
            <Currency toggle={toggle}/>
        </section>
    )
}

export default Buy
