import React from 'react'
import styles from './UbicationItem.module.scss'

const UbicationItem = ({sucursal, direccion, telefono}) => {

    return (
        <div className={styles.suc}>
            <p className={styles.sucu}>{sucursal}</p>
            <p className={styles.address}>Dirección: {direccion}</p>
            <p className={styles.phone}>Teléfono: <span>{telefono}</span></p>
        </div>
    )
}

export default UbicationItem;
