import React, {useState} from 'react'
import styled from 'styled-components';
import left from '../../images/left-arrow.svg'

const StyledInfo = styled.div`
    position: absolute;
    right: 13%;
    top: 50px;
    z-index: 1;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
    background-color: #FFFFFF;
    border-radius: 11px;
    width: 380px;
    height: auto;
    padding: 30px;
    .address1 {
        margin-left: 30px;
        margin-top: 15px;

        &.active {
            position: relative;
            &::before {
                position: absolute;
                content: '';
                top: 30px;
                left: -40px;
                width: 20px;
                height: 20px;
                background-image:url(${left});
                background-size: cover;
            }
            
        }
        .map-title {
            color: rgba(0, 0, 0, 0.54);
            font-family: 'Quicksand', sans-serif;
            font-size: 18px;
            font-weight: 700;
            line-height: 23px;
            text-align: left;
            margin-bottom: 6px;
            cursor: pointer;
        }
        .map-info {
            color: rgba(0, 0, 0, 0.54);
            font-family: 'Quicksand', sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 23px;
            text-align: left;
            .map-text {
                color: rgba(0, 0, 0, 0.54);
                font-family: 'Quicksand', sans-serif;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
            }
        }
    }
    .address2 {
        margin-top: 25px;
        margin-left: 30px;

        &.active {
            position: relative;
            &::before {
                position: absolute;
                content: '';
                top: 30px;
                left: -40px;
                width: 20px;
                height: 20px;
                background-image:url(${left});
                background-size: cover;
            }
            
        }
    }
    .map-title {
        color: rgba(0, 0, 0, 0.54);
        font-family: 'Quicksand', sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 23px;
        text-align: left;
        margin-bottom: 6px;
        cursor: pointer;
    }
    .map-info {
            color: rgba(0, 0, 0, 0.54);
            font-family: 'Quicksand', sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 23px;
            text-align: left;
            .map-text {
                color: rgba(0, 0, 0, 0.54);
                font-family: 'Quicksand', sans-serif;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
            }
        }
`;

const MapInfo = ({toQuintana, toCentral}) => {

    
    const [toggleClass, setToggleClass] = useState('active');
    const [address, setAddress] = useState(null);

    const Test = () => {
        setToggleClass('active');
        setAddress(null);
        toCentral();
    }
    const Test2 = () => {
        setToggleClass(null);
        setAddress('active');
        toQuintana()
    }
    return (
        <StyledInfo>
            <div onClick={() => Test()} className={`address1 ${toggleClass}`}>
                <div className="map-title">
                    Casa Central
                </div>
                <div className="map-info">
                    <div className="map-text">
                    Dirección: ADB, Posadas 1562, C1112 CABA
                    </div>
                    <div className="map-text">
                    Teléfono: 0800-220-CAMBIO (226246)
                    </div>
                    <div className="map-text">
                    Mail: info@cambioposadas.com.ar
                    </div>
                </div>
            </div>
            <div onClick={() => Test2()} className={`address2 ${address}`}>
                <div className="map-title">
                    Sucursal Quintana
                </div>
                <div className="map-info">
                    <div className="map-text">
                    Dirección: Av. Pres. Manuel Quintana 488, C1129 CABA
                    </div>
                    <div className="map-text">
                    Teléfono: (11) 4805-5626
                    </div>
                    <div className="map-text">
                    Mail: info@cambioposadas.com.ar
                    </div>
                </div>
            </div>
        </StyledInfo>
    )
}

export default MapInfo;
