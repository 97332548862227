import React from 'react'
import styles from './Details.module.scss';
import DetailItem from './DetailItem/DetailItem';
import Coin from '../../images/dolar-coin.png'
import coins from '../../images/coins.png'
import wallet from '../../images/wallet.png'
import hand from '../../images/hand.png'
const Details = ({toggle}) => {
    
    return (
        <section className={styles.container}>
            <div className={styles.cardsContainer}>
                <DetailItem img={Coin} width='65.15px' height='64.8px' title='SUPER SIMPLE DE OPERAR' description='A través de nuestra app o plataforma web.'/>
                <DetailItem img={coins} width='49px' height='45px' title='AHORRÁ DINERO' description='Tenemos mejores 
                cotizaciones que los bancos.'/>
                <DetailItem img={wallet} width='70.53px' height='70.15px' title='OPERACIÓN 100% SEGURA' description='Estamos regulados por el Banco 
                Central de la República Argentina.'/>
                <DetailItem img={hand} width='70.53px' height='70.15px' title='SIN COMISIONES' description='Obtené la mejor rentabilidad en tu transacción.'/>
            </div>
            <button onClick={toggle} className={styles.link} href="!#">Registrarse</button>
        </section>
    )
}

export default Details
