import React from 'react'
import styles from './ContactForm.module.scss';
import axios from 'axios';
import Swal from 'sweetalert2';
import {Formik, Field} from 'formik';
import * as Yup from 'yup';
import logo from '../../images/cambio-logo.png'
import twitter from '../../images/Twitter_White.svg'
import linkedin from '../../images/LinkedIN_White.svg'
import facebook from '../../images/facebook.svg'
import instagram from '../../images/instagram.png'

const ContactForm = () => {

    const contactSchema = Yup.object().shape({

        name: Yup.string()
        .required(),

        email: Yup.string()
        .email()
        .required(),

        phone: Yup.string()
        .required(),

        message: Yup.string()
        .required(),


    })

    const sendingForm = (values, reset) => {
        const url = 'http://mailingsystem-env.jtj3npc4yj.sa-east-1.elasticbeanstalk.com/public/send_mail';
        let {name, email, phone, message} = values;
        const {resetForm} = reset;
        const formData = new FormData();

        formData.append('clientName', 'Cambio Posadas');
        formData.append('senderName', name);
        formData.append('senderEmail', email);
        formData.append('message', 
        `Nombre: ${name} <br/>
        Mail: ${email} <br/>
        Teléfono: ${phone} <br/>
        Mensaje: ${message}`);
        formData.append('senderPhone', phone);
        formData.append('receiverEmail', 'info@cambioposadas.com.ar');
        formData.append('subject', 'Contacto desde la web Cambio Posadas');

        axios.post(url, formData)
            .then(res => {
                Swal.fire({
                    type: 'success',
                    title: 'Enviado',
                    text: 'Nos contactaremos a la brevedad'
                })
            })
            .catch(err => {
                Swal.fire({
                    type: 'error',
                    title: err.message,
                    text: 'Intenta nuevamente más tarde'
                })
            })
        resetForm({
            name: '',
            email: '',
            phone: '',
            message: '',
        });
    }

    return (
        <section className={styles.section}>
            <Formik
            initialValues={{
                name: '',
                email: '',
                phone: '',
                message: ''
            }}
            validationSchema={contactSchema}
            onSubmit={(values, {resetForm}) => sendingForm(values, {resetForm})}
            >
            {({handleSubmit, isValid}) => (
                <form className={styles.formContainer} onSubmit={handleSubmit}>
                    <div className={styles.formHeader}>
                        <img src={logo} alt="Cambio posadas"/>
                        Si necesitás más información o ayuda, <br/> dejanos tus datos y te responderemos de vuelta
                    </div>
                    <div className={styles.formBody}>
                        <Field className={styles.formInput} id="name" name="name" placeholder="Nombre y Apellido" />
                        <Field type="email" className={styles.formInput} id="email" name="email" placeholder="Email" />
                        <Field className={styles.formInput} id="phone" name="phone" placeholder="Teléfono" />
                        <Field type="textarea" className={`${styles.formInput} ${styles.formTextBox}`} id="message" name="message" placeholder="Escribí acá tu mensaje." />
                        <button type="submit" disabled={!isValid}>Enviar</button>
                        {!isValid && <p style={{color: '#cc0000', marginTop: '30px'}}>Completá los campos por favor.</p>}
                    </div>
                </form>
            )}
            </Formik>
            <footer className={styles.formFooter}>
            La primera plataforma creada en Argentina que te permite acceder al mercado libre de cambios regulado por el Banco<br/> Central de la República Argentina, desde tu casa, negocio u oficina, las 24 horas y los 365 días de año.
            <div className={styles.copyright}>
            <strong>© 2019 Copyright CambioPosadas SRL</strong>  /  Design by paisanos.io
            </div>
            </footer>
            <div className={styles.socials}>
                <a href="https://twitter.com/cambioposadas" target="_blank" rel="noopener noreferrer">
                    <img src={twitter} alt="Twitter"/>
                </a>
                <a href="https://www.linkedin.com/company/cambioposadas/about/" target="_blank" rel="noopener noreferrer">
                    <img src={linkedin} alt="Linkedin"/>
                </a>
                <a href="https://www.facebook.com/cambioposadas" target="_blank" rel="noopener noreferrer">
                    <img src={facebook} alt="Facebook"/>
                </a>
                <a href="https://www.instagram.com/cambioposadas/" target="_blank" rel="noopener noreferrer">
                    <img src={instagram} alt="Instagram"/>
                </a>
            </div>
            
        </section>
    )
}

export default ContactForm
