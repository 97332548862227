import React,{useState, useEffect} from 'react'
import styles from './CurrencyBox.module.scss'
import {ReactComponent as Lock} from '../../images/locked.svg'
import {ReactComponent as SSL} from '../../images/ssl.svg'
import usd from '../../images/usd.png';
import axios from 'axios';

const CurrencyBox = ({toggle}) => {
    const [currencyId, setCurrencyId] = useState("USD");
    const [currencyList, setCurrencyList] = useState([]);
    useEffect(() => {
        getCurrencyList()
    }, [])

    let urlImg = `https://resources.cambioposadas.com.ar/flags/${currencyId}.png`;
    let currentCurrency = currencyList.find(currency =>{
        return currency.id === currencyId
    })
    async function getCurrencyList() {
        let response = await axios.get("https://api.cambioposadas.com.ar/public/currency/ARS", {headers: {'Authorization' : "Basic Q2FtYmlvUG9zYWRhczpGYWN1SXNHYXlzaGFu" }})
        let responseMod = response.data.map(currency =>{
            if(currency.id === "USD"){
                return {
                    ...currency, 
                    name: "Dolar Estadounidense"
                }
            }else if(currency.id === "EUR"){
                return {
                    ...currency, 
                    name: "Euros"
                }
            }else if(currency.id === "ARS"){
                return {
                    ...currency, 
                    name: "Pesos Argentinos"
                }
            }else if(currency.id === "BRL"){
                return {
                    ...currency, 
                    name: "Reales"
                }
            }else{
                return currency
            }
        })
        setCurrencyList(responseMod)
      }

      console.log(currencyList)
      console.log(currencyId)
      console.log(currentCurrency)
    return (
        <div className={styles.boxContainer}>
            <div className={styles.transactionsContainer}>
                <div className={styles.divisaContainer}>
                    <div className={styles.divisaFlag}>
                        <img src={urlImg} alt="USD"/>
                    </div>
                    <div className={styles.divisaText}>
                    <select onChange={(e) => setCurrencyId(e.target.value)}>
                      {currencyList.map(currency =>{
                          return <option value={currency.id}>{currency.id}</option>
                      })}
                    </select>
                        <span>{currentCurrency ? currentCurrency.name : ""}</span>
                    </div>
                </div>
                <div className={styles.transaccion}>Compra <span>${currentCurrency ? currentCurrency.providers[0].bid.toFixed(2) : ""}</span></div>
                <div className={styles.transaccion}>Venta <span>${currentCurrency ? currentCurrency.providers[0].ask.toFixed(2) : ""}</span></div>
            </div>
            <div className={styles.secure}>
                <div className={styles.mode}><Lock />Módulo Seguro</div>
                <div className={styles.ssl}><SSL /></div>
            </div>
        <button onClick={toggle} className={styles.linkTo} href="!#">Registrate y Empezá</button>
        </div>
    )
}

export default CurrencyBox
