import React from 'react'
import styles from './NuestraUbicacion.module.scss'
import UbicationItem from './UbicationItem/UbicationItem'

const NuestraUbicacion = () => {
    return (
        <section className={styles.container}>
            <h2>Nuestra Ubicación</h2>
            <div className={styles.itemsContainer}>
            <UbicationItem 
            sucursal="Casa Central"
            direccion="ADB, Posadas 1562. C1112 CABA."
            telefono="0800-220-CAMBIO (226246)"
            />
            <UbicationItem 
            sucursal="Sucursal Quintana"
            direccion="Av. Pres. Manuel Quintana 488, C1129 CABA."
            telefono="(11) 4805-5626"
            />
            </div>
            
        </section>
    )
}

export default NuestraUbicacion
