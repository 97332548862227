import React from 'react'
import styles from './DetailsMobile.module.scss'
import DetailsMobileItem from './DetailsMobileItem/DetailsMobileItem'
import Coin from '../../images/dolar-coin.png'
import coins from '../../images/coins.png'
import wallet from '../../images/wallet.png'
import hand from '../../images/hand.png'
const DetailsMobile = () => {
    return (
        <section className={styles.container}>
            <DetailsMobileItem 
            img={Coin} 
            title='SUPER SIMPLE DE OPERAR'
            description='A través de nuestra app o plataforma web.'
            />
            <DetailsMobileItem 
            img={coins} 
            title='AHORRÁ DINERO'
            description='Tenemos mejores cotizaciones que los bancos.'
            />
            <DetailsMobileItem 
            img={wallet} 
            title='OPERACIÓN 100% SEGURA'
            description='Estamos regulados por el Banco Central de la República Argentina.'
            />
            <DetailsMobileItem 
            img={hand} 
            title='SIN COMISIONES'
            description='Obtené la mejor rentabilidad en tu transacción.'
            />
            <button className={styles.link} href="!#">Registrarse</button>
        </section>
    )
}

export default DetailsMobile
